<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            :labels="labels"
            :items="exercises"
            :pagination="pagination"
            :options="{ sort: true, filters: true, link: true }"
            :filters="filters"
            @row-click="$router.push(`/exercises/${$event.id}/edit`)"
            @mouse-wheel-click="$openNewTab(`/exercises/${$event.id}/edit`)"
            @pagination-change="onPaginationChange"
            @add-button-click="$router.push('/exercises/add')"
            @filter-change="onFilterChange"
            @elements-moved="onElementsMoved"
        >
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="removeExercise(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    page: {
        title: 'Exercises'
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Exercises',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Exercises',
                    active: true
                }
            ],
            exercises: [],
            labels: [
                { value: 'name', width: '45%' },
                { value: 'slug', width: '35%' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '20%',
                    type: 'date'
                },
                {
                    value: 'actions',
                    sortable: false,
                    width: '5%',
                    filterable: false
                }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters)
        };
    },

    created() {
        this.fetchExercises();
    },

    methods: {
        ...mapActions({
            getExercises: 'exercises/index',
            deleteExercise: 'exercises/delete',
            changeOrder: 'exercises/changeOrder'
        }),

        async fetchExercises() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getExercises(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.exercises = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async removeExercise(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this exercise?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteExercise(id);

                this.exercises = this.exercises.filter(
                    exercise => exercise.id !== id
                );

                this.pagination.total--;

                this.$toaster('Exercise has been deleted.');
            } catch (error) {
                console.error(error);

                const message =
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                        ? error.response.data.error
                        : 'Something went wrong!';

                this.$toasterError(message);
            }
        },

        async onElementsMoved(elements) {
            try {
                await this.changeOrder(elements);

                this.$toaster('Order has been changed.');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchExercises();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };

            this.fetchExercises();
        }
    }
};
</script>
